/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: BudmoJiggler;
  src: url(../public/fonts/BudmoJiggler-Regular.ttf);
  src: url('../public/fonts/BudmoJiggler-Regular.eot?#iefix') format('embedded-opentype'), url(../public/fonts/BudmoJiggler-Regular.woff2) format('woff2'), url(../public/fonts/BudmoJiggler-Regular.woff) format('woff'), url(../public/fonts/BudmoJiggler-Regular.ttf) format('truetype');
}


body {
  margin: 0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #000;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(255, 255, 255, 0.87);
  width: 100%;

}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}

a:hover {
  color: #535bf2;
}

@font-face {
  font-family: "neon-tubes-2-regular";
  src:
    url("https://assets.codepen.io/2585/NeonTubes2.otf") format("woff"),
    url("https://assets.codepen.io/2585/NeonTubes2.otf") format("opentype"),
    url("https://assets.codepen.io/2585/NeonTubes2.otf") format("truetype");
}

.neon-red {
  --neon: hsl(355 100% 100%);
  --neon-glow: #bc13fe;
}

.neon-blue {
  --neon: hsl(192 100% 100%);
  --neon-glow: #0e66da;
}


h1>i {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 1.3;
  color: var(--neon);
  text-shadow:
    0 0 40px var(--neon-glow),
    0 0 5.5vmin var(--neon-glow),
    0 0 8vmin var(--neon-glow),
    0 0 15vmin var(--neon-glow),
    0 0 25vmin var(--neon-glow);
  -webkit-animation: text-animation 4s linear infinite;
  animation: text-animation 4s linear infinite;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

@-webkit-keyframes text-animation {

  0%,
  100% {
    text-shadow:
      0 0 20px var(--neon-glow),
      0 0 2.5vmin var(--neon-glow),
      0 0 5vmin var(--neon-glow),
      0 0 10vmin var(--neon-glow),
      0 0 15vmin var(--neon-glow);
  }

  50% {
    text-shadow:
      0 0 5px var(--neon-glow),
      0 0 2vmin var(--neon-glow),
      0 0 2vmin var(--neon-glow),
      0 0 2vmin var(--neon-glow),
      0 0 2vmin var(--neon-glow);
  }
}

@keyframes text-animation {

  0%,
  100% {
    text-shadow:
      0 0 20px var(--neon-glow),
      0 0 2.5vmin var(--neon-glow),
      0 0 5vmin var(--neon-glow),
      0 0 10vmin var(--neon-glow),
      0 0 15vmin var(--neon-glow);
  }

  50% {
    text-shadow:
      0 0 5px var(--neon-glow),
      0 0 2vmin var(--neon-glow),
      0 0 2vmin var(--neon-glow),
      0 0 2vmin var(--neon-glow),
      0 0 2vmin var(--neon-glow);
  }
}

@media (dynamic-range: high) {
  .neon-red {
    --neon-glow: color(display-p3 1 0 0);
  }

  .neon-blue {
    --neon-glow: color(display-p3 0 0.75 1);
  }

  h1>i {
    text-shadow:
      0 0 2.5vmin var(--neon-glow),
      0 0 10vmin var(--neon-glow),
      0 0 15vmin var(--neon-glow);
  }
}

h1 {
  text-align: center;
  font-size: 25vmin;
  font-weight: normal;
}


h1 {
  font-size: 3.2em;
  line-height: 1.1;
  font-family: neon-tubes-2-regular;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  /* text-shadow: 0 0 7px transparent, 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe, 0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe; */
  text-transform: uppercase;
}

.nft_card {
  border-radius: 20px;
  -webkit-box-shadow: 0 2px 4px hsla(0, 0%, 100%, .2), 0 7px 13px -3px hsla(0, 0%, 100%, .1), inset 0 -3px 0 hsla(0, 0%, 100%, .1);
  box-shadow: 0 2px 4px hsla(0, 0%, 100%, .2), 0 7px 13px -3px hsla(0, 0%, 100%, .1), inset 0 -3px 0 hsla(0, 0%, 100%, .1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  place-content: center;
  place-items: center;
  position: relative;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.nft_card:before {
  -webkit-animation: rotBGimg 4.2s linear infinite;
  animation: rotBGimg 4.2s linear infinite;
  background: transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#e710d5), to(#0e66da));
  background-image: -o-linear-gradient(top, #e710d5, #0e66da);
  background-image: linear-gradient(180deg, #e710d5, #0e66da);
  /* box-shadow: 0px 1px 20px 23px #e710d5,0px 1px 20px 23px #0e66da; */
  content: "";
  height: 130%;
  position: absolute;
  -webkit-transition: all 2.5s linear;
  -o-transition: all 2.5s linear;
  transition: all 2.5s linear;
  width: 100px;
}

@-webkit-keyframes rotBGimg {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotBGimg {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Liberty";
  src: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/907368/liberty.otf');
}

/* .heading-text {
  font-family: 'Liberty';
  font-weight: 100;
  font-size: 7rem;
  letter-spacing: -0.02em;
  flex-flow: row;
}

.heading-text .letter {
  color: #d9fdff;
  text-shadow: 0 0 2rem #bc13fe;
  display: inline-block;
}

.heading-text .letter.letter-2 {
  transform: translate(-0.2rem, 1rem) rotate(10deg);
  animation: flicker 2s ease-in-out infinite alternate;
}

@keyframes flicker {
  0% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  5% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  5.5% {
    opacity: 0;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  6% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  6.5% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  7% {
    opacity: 0;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  8% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }

  50% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(13deg);
  }

  100% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
} */


.nft_card .img {
  background: rgb(0, 0, 0, 10%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  -webkit-box-shadow: inset 0px -1px 12px 7px rgb(0 0 0);
  box-shadow: inset 0px -1px 12px 7px rgb(0 0 0);
}

.nft_card .btn-border {
  border-bottom: 5px solid #242424;
  border-left: 5px solid #242424;
  border-right: 5px solid #242424;
  -webkit-box-shadow: inset -2px -5px 7px 3px rgb(0 0 0);
  box-shadow: inset -2px -5px 7px 3px rgb(0 0 0);
  border-radius: 0px 0px 15px 15px;
  background: -o-linear-gradient(289deg, #0f0f0f, #242424);
  background: linear-gradient(161deg, #0f0f0f, #242424);

}

.nft_card .content .img {
  border-radius: 0 0px 25px 25px;
}

.nft_card:after {
  background: #242424;
  border-radius: 20px;
  content: "";
  inset: 3px;
  position: absolute;
}

.nft_card .content {
  background: -o-linear-gradient(306deg, #8608b4, #492fed 60%, #bd6fda);
  background: linear-gradient(144deg, #8608b4, #492fed 60%, #bd6fda);
  -webkit-box-shadow: 2px -2px 10px black;
  box-shadow: 2px -2px 10px black;
  border-top: 5px solid #242424;
}

.nft_card .content .content-border {
  border-bottom: 5px solid #242424;
  border-left: 5px solid #242424;
  border-right: 5px solid #242424;
  border-radius: 0px 0px 26px 26px;
}

.nft-conver {
  position: relative;
  border-radius: 15px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.nft-conver:hover .nft_card {
  -webkit-box-shadow: 0px 2px 51px 5px rgb(134 8 180 / 30%), 26px 7px 65px -29px rgb(73 47 237 / 30%), 0px 6px 50px 3px rgb(189 111 218 / 30%);
  box-shadow: 0px 2px 51px 5px rgb(134 8 180 / 30%), 26px 7px 65px -29px rgb(73 47 237 / 30%), 0px 6px 50px 3px rgb(189 111 218 / 30%);
}


.nft-conver .label {
  position: absolute;
  overflow: hidden;
  width: 150px;
  height: 150px;
  top: -10px;
  left: -10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 20;
}

.nft-conver .label .label_no {
  width: 170px;
  height: 30px;
  position: absolute;
  top: 31%;
  -webkit-transform: rotate(312deg);
  -ms-transform: rotate(312deg);
  transform: rotate(312deg);
  left: -13%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nft-conver .label::before {
  content: '';
  position: absolute;
  width: 150%;
  height: 40px;
  background-image: -o-linear-gradient(45deg, #ff6547 0%, #ffb144 51%, #ff7053 100%);
  background-image: linear-gradient(45deg, #ff6547 0%, #ffb144 51%, #ff7053 100%);
  -webkit-transform: rotate(-45deg) translateY(-20px);
  -ms-transform: rotate(-45deg) translateY(-20px);
  transform: rotate(-45deg) translateY(-20px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;

}

.nft-conver .label::after {
  content: '';
  position: absolute;
  width: 10px;
  bottom: 0;
  left: 0;
  height: 10px;
  z-index: -1;
  -webkit-box-shadow: 140px -140px #cc3f47;
  box-shadow: 140px -140px #cc3f47;
  background-image: -o-linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
}

/*  */

/* ---------------------------------history-section------------------------------ */


.history-section {
  min-height: calc(100vh - 60px);

}

.history-section table:before {
  -webkit-animation: rotBGimg 4.2s linear infinite;
  animation: rotBGimg 4.2s linear infinite;
  background: transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#e710d5), to(#0e66da));
  background-image: -o-linear-gradient(top, #e710d5, #0e66da);
  background-image: linear-gradient(180deg, #e710d5, #0e66da);
  /* box-shadow: 0px 1px 20px 23px #e710d5, 0px 1px 20px 23px #0e66da; */
  content: "";
  height: 240%;
  position: absolute;
  -webkit-transition: all 2.5s linear;
  -o-transition: all 2.5s linear;
  transition: all 2.5s linear;
  width: 100px;
  top: -86%;
  z-index: -1;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
}

.history-section table:after {
  background: #242424;
  border-radius: 25px;
  content: "";
  inset: 4px;
  position: absolute;
  border-radius: 15px;
  border-radius: 15px;
  z-index: -1;
}

.history-section table {
  /* background: linear-gradient(#212121, #212121) padding-box,
    linear-gradient(155deg, transparent 35%, #e81cff, #40c9ff) border-box; */
  border: 2px solid transparent;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.history-section table tbody tr {
  display: flex;
  justify-content: space-between;
}

.history-section table th {
  width: 16.66%;
  text-align: center;
}

.history-section table td {
  width: 16.66%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.history-section table tbody tr:hover {
  background-color: rgb(255, 255, 255, 0.1);
}

.history-section table tbody tr:hover button {
  opacity: 1;
}

.history-section table thead tr {
  background: #e81cff;
  display: flex;
  justify-content: space-between;
}

/* ---------------------------------history-section------------------------------ */

.vol-btn img {
  -webkit-animation: metamaskanimation 2s linear infinite;
  animation: metamaskanimation 2s linear infinite;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.arrow {
  rotate: 180deg;
}

@-webkit-keyframes metamaskanimation {

  0%,
  100% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  50% {
    -webkit-transform: translatey(5px);
    transform: translatey(5px);

  }
}

@keyframes metamaskanimation {

  0%,
  100% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  50% {
    -webkit-transform: translatey(5px);
    transform: translatey(5px);

  }
}

.voltage-button {
  position: relative;
}


button:active {
  -webkit-transform: translateY(5px);
  -ms-transform: translateY(5px);
  transform: translateY(5px);
  /* box-shadow: 0px 0px 0px 0px #a29bfe; */
}

.voltage-button button {
  color: white;
  background: #0D1127;
  padding: 8px 25px;
  border-radius: 5rem;
  border: 4px solid #5978F3;
  font-size: 16px;
  line-height: 1em;
  letter-spacing: 0.075em;
  -webkit-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
}

.voltage-button button:hover {
  cursor: pointer;
  background: #0F1C53;
}

.voltage-button button:hover+svg,
.voltage-button button:hover+svg+.dots {
  opacity: 1;
}

.voltage-button svg {
  display: block;
  position: absolute;
  top: -0.75em;
  left: -0.25em;
  width: calc(100% + 0.5em);
  height: calc(100% + 1.5em);
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  transition: opacity 0.4s;
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.voltage-button svg path {
  stroke-dasharray: 100;
  -webkit-filter: url("#glow");
  filter: url("#glow");
}

.voltage-button svg path.line-1 {
  stroke: #535bf2;
  stroke-dashoffset: 0;
  -webkit-animation: spark-1 3s linear infinite;
  animation: spark-1 3s linear infinite;
}

.voltage-button svg path.line-2 {
  stroke: #271291;
  stroke-dashoffset: 500;
  -webkit-animation: spark-2 3s linear infinite;
  animation: spark-2 3s linear infinite;
}

.voltage-button .dots {
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.voltage-button .dots .dot {
  width: 1rem;
  height: 1rem;
  background: white;
  border-radius: 100%;
  position: absolute;
  opacity: 0;
}

.voltage-button .dots .dot-1 {
  top: 0;
  left: 20%;
  -webkit-animation: fly-up 3s linear infinite;
  animation: fly-up 3s linear infinite;
}

.voltage-button .dots .dot-2 {
  top: 0;
  left: 55%;
  -webkit-animation: fly-up 3s linear infinite;
  animation: fly-up 3s linear infinite;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.voltage-button .dots .dot-3 {
  top: 0;
  left: 80%;
  -webkit-animation: fly-up 3s linear infinite;
  animation: fly-up 3s linear infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.voltage-button .dots .dot-4 {
  bottom: 0;
  left: 30%;
  -webkit-animation: fly-down 3s linear infinite;
  animation: fly-down 3s linear infinite;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.voltage-button .dots .dot-5 {
  bottom: 0;
  left: 65%;
  -webkit-animation: fly-down 3s linear infinite;
  animation: fly-down 3s linear infinite;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

@-webkit-keyframes spark-1 {
  to {
    stroke-dashoffset: -1000;
  }
}

@keyframes spark-1 {
  to {
    stroke-dashoffset: -1000;
  }
}

@-webkit-keyframes spark-2 {
  to {
    stroke-dashoffset: -500;
  }
}

@keyframes spark-2 {
  to {
    stroke-dashoffset: -500;
  }
}

@-webkit-keyframes fly-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0) scale(0.2);
    transform: translateY(0) scale(0.2);
  }

  5% {
    opacity: 1;
    -webkit-transform: translateY(-1.5rem) scale(0.4);
    transform: translateY(-1.5rem) scale(0.4);
  }

  10%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(-3rem) scale(0.2);
    transform: translateY(-3rem) scale(0.2);
  }
}

@keyframes fly-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0) scale(0.2);
    transform: translateY(0) scale(0.2);
  }

  5% {
    opacity: 1;
    -webkit-transform: translateY(-1.5rem) scale(0.4);
    transform: translateY(-1.5rem) scale(0.4);
  }

  10%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(-3rem) scale(0.2);
    transform: translateY(-3rem) scale(0.2);
  }
}

@-webkit-keyframes fly-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0) scale(0.2);
    transform: translateY(0) scale(0.2);
  }

  5% {
    opacity: 1;
    -webkit-transform: translateY(1.5rem) scale(0.4);
    transform: translateY(1.5rem) scale(0.4);
  }

  10%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(3rem) scale(0.2);
    transform: translateY(3rem) scale(0.2);
  }
}

@keyframes fly-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0) scale(0.2);
    transform: translateY(0) scale(0.2);
  }

  5% {
    opacity: 1;
    -webkit-transform: translateY(1.5rem) scale(0.4);
    transform: translateY(1.5rem) scale(0.4);
  }

  10%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(3rem) scale(0.2);
    transform: translateY(3rem) scale(0.2);
  }
}

/*  */


/* ----------Canvas---------- */

canvas {
  width: 100% !important;
  height: 100% !important;
}

.canvas-loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: mulShdSpin 1.1s infinite ease;
  animation: mulShdSpin 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes mulShdSpin {

  0%,
  100% {
    -webkit-box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }


  12.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

@keyframes mulShdSpin {

  0%,
  100% {
    -webkit-box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }


  12.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

/* ----------Canvas---------- */


/* ---------------------------------lazy-loader---------------------------------- */

.bgVideoBody {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
  z-index: -1;
  opacity: 1;
  mix-blend-mode: difference
}

.loaderAnim {
  -o-object-fit: contain;
  object-fit: contain;
  height: 80px;
  width: 80px
}

.bgBody {
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1
}

/* ---------------------------------lazy-loader---------------------------------- */



/* ---------------------------------footer------------------------------- */

.footer_social ul li {
  margin-right: 10px;
  padding-right: 20px;
  position: relative;
}

.footer_social ul li.discord a {
  width: 22px;
  height: 22px;
}

.footer_social ul li a {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
}

.footer_social ul li a:hover svg {
  fill: #ff8fff;
}

.footer_social ul li a svg {
  height: 100%;
  width: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.footer_social ul li:before {
  content: "||";
  position: absolute;
  right: 0;
  top: 40%;
  -webkit-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  transform: translatey(-50%);
}


.footer_social ul li:last-child::before {
  content: "";
}

/* ---------------------------------footer------------------------------- */

/* @media (min-width: 1536px) {
  .container {
    max-width: 1920px !important;
  }
} */

.footer {
  /* position: fixed; */
  left: 0;
  bottom: 0;
}


@media only screen and (max-width: 768px) {
  .history-section table td {
    width: 120px;
    text-align: center;
  }

  .history-section table th {
    width: 30%;
    text-align: center;
  }

  .history-section table tr .voltage-button button {
    padding: 5px 13px;
  }
}

@media only screen and (max-width: 575px) {

  .footer_social ul li a {
    height: 20px;
    width: 20px;
  }

  .footer_social ul li {
    margin-right: 0px;
    padding-right: 8px;
  }

  .footer_social ul li.discord a {
    height: 21px;
    width: 22px;
  }

}

@media only screen and (max-width: 425px) {
  .voltage-button button {
    padding: 8px 8px;
  }
}




.myPagination {
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 20px;
  width: fit-content;
  background-color: #ffffff73;
}


.numb {
  background: #e81cff96;
  padding-right: 3px;
  padding-left: 3px;
  border-radius: 3px;
}

.newsmarquee {
  position: relative;
  /* margin-top: 140px; */
  padding: 20px 0;
}

.marquee {
  font-size: 18px;
  color: #fff;
  height: 40px;
  overflow: hidden;
  background-color: transparent;
  position: relative;
  line-height: 2.2;
  margin-bottom: 15px;
  padding: 0px;
  letter-spacing: 2px;
  font-weight: 400;
  box-shadow: inset 0px 0px 10px -8px #FFFFFF;
  background-image: linear-gradient(to right, #ffffff00, #E900C2, #ffffff00);
  text-transform: uppercase;
}

.marquee::before {
  content: "";
  background-image: url(../src/assets/announcement.gif);
  display: block;
  width: 45px;
  height: 100%;
  background-size: contain;
  position: absolute;
  left: 5px;
  top: 0;
  background-repeat: no-repeat;
  z-index: 2;
}

.marquee::after {
  content: "";
  background-image: linear-gradient(to right, #1F232F 70%, transparent);
  display: block;
  width: 100px;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0;
  z-index: 1;
}

.marquee b {
  color: #FFD196;
}

.marquee img {
  display: inline-block;
  width: 30px;
  object-fit: contain;
  position: relative;
  top: -3px;
  animation: marqueezoomInOut 1s ease-out infinite;
}

@keyframes marqueezoomInOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.marquee marquee {
  position: relative;
  z-index: 1;
}

.wrgbtn {
  list-style: none;
  background-color: #bc4242;
  padding: 6px 8px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  align-items: center;

}

.web3gamesfix {
  position: fixed;
  top: calc(50% - 160px);
  right: 0px;
  transform: translate(0%, -50%);
  z-index: 999;
  width: 50px;
  background-color: #000000;
  padding: 1px 5px 6px;
  border-radius: 7px 0px 0px 7px;
  box-shadow: inset 2px 0px 11px -3px #ffffff;
}
.web3gamesfix a::before {
  content: "Live";
  color: #ff7171;
  font-size: 12px;
  position: relative;
  top: 0px;
  left: 8px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: bold;
}
.web3gamesfix a::after {
  content: "Web3";
  color: #ff7171;
  font-size: 12px;
  position: relative;
  top: -2px;
  left: 4px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: bold;
}
.web3gamesfix a {
  display: block;
  text-decoration: none;
}

.web3gamesfix img {
  display: block;
  width: 100%;
  height: auto;
}


.imagebox {
  max-width: 160px;
  margin: 30px auto;
  border-radius: 50%;
  text-align: center;
}
.imagebox img {
  animation: move 3s linear infinite;
  transition: all 0.5s;
}
.titlebg {
  color: #2196F3;
  font-size: 25px;
}
.joinbtn{
  background-color: #03A9F4;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bolder;
}
.MuiPaper-root {
  background-color: #000 !important;
  box-shadow: inset 0 0 19px #f0e5e5d4 !important;
  padding-inline: 7px;
  padding-block: 7px;
  max-width: 650px !important;
  border-radius: 14px !important;
  position: relative !important;
  background: #f00;
}
.addbgneon .MuiPaper-root {
 &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(315deg,#03A9F4,#FF0058);
}
&::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(315deg,#03A9F4,#FF0058);
    filter: blur(30px);
}
}
.MuiTabs-flexContainer {
justify-content: center;
}
.boxcontent {
  background: #000;
  position: relative;
  z-index: 99;
  border-radius: 6px;
  overflow: auto;
}
.InputContainer {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  border-radius: 18px;
  overflow: hidden;
  cursor: pointer;
  padding-left: 0;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
  padding-inline: 5px;
}
.MuiButtonBase-root {
  color: #Fff !important;
  background: #2692E0 !important;
}
.MuiTabs-scroller {
  .MuiTabs-indicator {
    background-color: #fff;
  }
}
.MuiDialogContent-root {
  .MuiTabPanel-root {
    padding: 15px 0px;
  }
}
.input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 0.9em;
  caret-color: rgb(255, 81, 0);
}
.labelforsearch {
  cursor: text;
  padding: 0px 12px;
}
.searchIcon {
  width: 13px;
}
.border {
  height: 40%;
  width: 1.3px;
  background-color: rgb(223, 223, 223);
}
.micIcon {
  width: 12px;
}
.micButton {
  /* padding: 0px 15px 0px 12px; */
  border: none;
  background-color: transparent;
  /* height: 40px; */
  cursor: pointer;
  transition-duration: 0.3s;
  max-width: 35px;
  border-radius: 50%;
  background: #2196F3;
  height: 35px;
  margin-left: 30px;
}
.micButton svg {
  max-width: 100%;
  height: auto;
  filter: invert(1);
}
.searchIcon path {
  fill: rgb(114, 114, 114);
}
.micIcon path {
  fill: rgb(255, 81, 0);
}
.micButton:hover {
  filter: brightness(0.8);
  transition-duration: .3s;
}
.popuptitle {
  font-size: 35px;
}
@keyframes move {
   0%{transform: scale(0.7)}
  50%{transform: scale(1)}
  100%{transform: scale(0.7)}
}
@media (max-width:768px) {
  .imagebox {
      max-width: 160px;
      margin: 0 auto;
  }
  .popuptitle {
    font-size: 28px;
  }
}

/* Hide <td> on screens smaller than 768px */
@media (max-width: 1199px) {
  .hide-on-small {
    display: none;
    visibility: hidden;
    opacity: 0;
    z-index: -10;
    position: absolute;
  }

  .show-on-small {
    display: block;
  }
}

@media (min-width: 1200px) {
  .show-on-small {
    display: none !important;
  }
}


@media (max-width: 400px) {
  .voltage-button button {
    font-size: 13px;
  }
}